@charset "UTF-8";

@font-face {
  font-family: 'Museo';
  font-style: normal;
  font-weight: 300;
  src: url(/static/webfonts/MuseoSans_300.otf) format('opentype');
}


@font-face {
  font-family: 'Museo';
  font-style: normal;
  font-weight: 500;
  src: url(/static/webfonts/MuseoSans_500.otf) format('opentype');
}

@font-face {
  font-family: 'Museo';
  font-style: normal;
  font-weight: 700;
  src: url(/static/webfonts/MuseoSans_700.otf) format('opentype');
}

@font-face {
  font-family: 'Museo';
  font-style: normal;
  font-weight: 900;
  src: url(/static/webfonts/MuseoSans_900.otf) format('opentype');
}
