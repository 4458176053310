.tab-view{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tab-view-head{
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  margin-bottom: -4px;

  &::-webkit-scrollbar
    {
      width: 0;
    height: 0;
    }
}

.tab-view-head-item{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  padding: 15px;
  margin-right: 5px;
  background-color: #eeeeee;
  border: 4px solid #eee;
  border-bottom: none;
  z-index: 1;
  white-space: nowrap;
  cursor: pointer;

  &.is-active{
    background-color: #fff;
    color: var(--ignt-color-primary);
    font-weight: bold;
  }
}

.tab-view-main{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
}

.tab-view-slide{
  display: none;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  object-fit: cover;
  border: 4px solid #eee;

  &.is-active{
    display: flex;
  }
}

.tab-view-img{
  display: flex;
}
