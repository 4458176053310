@charset "UTF-8";

.hljs {
  overflow-x: auto;
  padding: 0.5em
}

.hljs, .hljs-subst {
  color: #444
}

.hljs-comment {
  color: #888888
}

.hljs-keyword, .hljs-attribute, .hljs-selector-tag, .hljs-meta-keyword, .hljs-doctag, .hljs-name {
  font-weight: bold
}

.hljs-type, .hljs-string, .hljs-number, .hljs-selector-id, .hljs-selector-class, .hljs-quote, .hljs-template-tag, .hljs-deletion {
  color: #880000
}

.hljs-title, .hljs-section {
  color: #880000;
  font-weight: bold
}

.hljs-regexp, .hljs-symbol, .hljs-variable, .hljs-template-variable, .hljs-link, .hljs-selector-attr, .hljs-selector-pseudo {
  color: #BC6060
}

.hljs-literal {
  color: #78A960
}

.hljs-built_in, .hljs-bullet, .hljs-code, .hljs-addition {
  color: #397300
}

.hljs-meta {
  color: #1f7199
}

.hljs-meta-string {
  color: #4d99bf
}

.hljs-emphasis {
  font-style: italic
}

.hljs-strong {
  font-weight: bold
}

.blog-presentation{
  h1 {
    font-size: 2em !important;
    padding-bottom: 0;
    margin: 15px 0;
    font-weight: 900;
  }

  ul {
    padding: 0 0 0 30px;
    margin: 15px 0;
  }

  hr {
    border-top: 1px dotted var(--ignt-color-primary);
    margin: 25px 0;
  }

  li {
    list-style: disc;
  }

  pre {
    padding: 10px 15px;
    font-size: 0.9em;
    border-radius: 3px;
    tab-size: 4;
    hyphens: none;
    margin: 15px 0;
  }

  code {
    padding: 3px 5px;
    font-size: 0.9em;
    border-radius: 3px;
    line-height: 1.7;
    hyphens: none;
  }
}
