@charset "UTF-8";

.feature {
  &-wrap {
    display: flex;
    flex-direction: column;

    @media (--ignt-mq-min-break-medium) {
      flex-direction: row;
    }
  }

  &-container {
    flex: 1 1 50%;
  }
}
