@charset "UTF-8";

.grid{
  display: grid;
  width: 100%;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: 40px;

  @media (--ignt-mq-max-break-medium){
    grid-template-columns: minmax(0, 1fr);
  }
}
