@charset "UTF-8";

.thumbnail {
  display: block;
  padding: .5em;
  margin-bottom: 1em;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: .2s ease border;

  a:hover & {
    border-color: var(--ignt-color-primary);
  }

  &-caption {
    margin-top: .5em;
    padding: 1em;
  }

  p {
    color: var(--ignt-color-text-default);
  }
}
