
.blog-entry{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;

  @media (--ignt-mq-max-break-medium){
    flex-direction: column;
  }
}

.blog-wrap{
  display: flex;
  flex-direction: column;
}

.blog-headline{
}

.blog-prev{
  color: var(--ignt-color-text-default);
  max-width: 200ch;
}

.blog-img{
  display: flex;
  flex: 0 0 30%;
  width: 30%;
  margin-right: 40px;
  aspect-ratio: 1.3;
  object-fit: cover;

  @media (max-width: 1060px) {
    aspect-ratio: 1.2;
  }

  @media (--ignt-mq-max-break-medium){
    flex: 0 0 100%;
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    aspect-ratio: 2.6;

    &:after{
      content: none;
    }
  }

  @media (--ignt-mq-max-break-small){
    aspect-ratio: 2;
  }
}

.blog-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 30px;
  margin-top: 110px;
  font-size: 1.2em;
  background: linear-gradient(to left, var(--ignt-color-secondary), var(--ignt-color-primary));

  .headline{
    margin-bottom: 0;
  }

  @media (--ignt-mq-max-break-medium){
    flex-direction: column-reverse;
    align-items: flex-start;
    background: transparent;
    font-size: 1em;
  }

  @media (--ignt-mq-min-break-medium){
    .headline{
      margin: 20px auto 20px 30px;
      color: #fff;
    }
  }
}

.blog-main-img{
  width: 35%;
  flex: 0 0 35%;
  margin-left: 40px;
  aspect-ratio: 1.4;
  overflow: hidden;

  @media (--ignt-mq-max-break-medium){
    width: 100%;
    margin-left: 0;
    margin-bottom: 40px;
    aspect-ratio: 2;
    flex: 1 1 auto;
  }

  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.blog-text{
  display: flex;
  flex-direction: column;
  margin-bottom: var(--sl-spacing-large);

  h2 {
    color: var(--ignt-color-primary);
    font-weight: 700;
    font-size: 1.7em;
    margin-bottom: .5em;
  }

  h3 {
    color: var(--ignt-color-primary);
    font-weight: 700;
    font-size: 1.5em;
    margin-bottom: .5em;
  }

  h4 {
    color: var(--ignt-color-primary);
    font-weight: 700;
    font-size: 1.3em;
    margin-bottom: .5em;
  }

  h5 {
    color: var(--ignt-color-primary);
    font-weight: 700;
    font-size: 1.2em;
    margin-bottom: .5em;
  }

  h6 {
    color: var(--ignt-color-primary);
    font-weight: 700;
    font-size: 1em;
    margin-bottom: .5em;
  }

  ul{
    padding: var(--sl-spacing-large);
    list-style: disc;

    li{
      margin-bottom: var(--sl-spacing-small);
    }
  }

  ol{
    padding: var(--sl-spacing-large);
    list-style: decimal;

    li{
      margin-bottom: var(--sl-spacing-small);
    }
  }

  a{
    color: var(--ignt-color-primary);
  }
}

.file-application_pdf{
  margin-bottom: var(--sl-spacing-small);
}
