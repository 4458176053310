:root,
:host {
  --ignt-theme-name: "Geodox";

  --ignt-color-primary: #d00f1c;
  --ignt-color-secondary: #ff5d36;
  --ignt-color-background: #fafafa;

  --ignt-color-text-default: #222;

  --ignt-color-action: #da6c00;
  --ignt-color-success: #419839;
  --ignt-color-fail: #b10000;
  --ignt-color-hint: #4785a7;

  --ignt-header-gradient: linear-gradient(to left, var(--ignt-color-secondary), var(--ignt-color-primary));

    /* Buttons, Inputs, Forms and Menus */
  --ignt-color-form-default: var(--ignt-color-neutral-0);

  /* Font sizes */
  --ignt-font-size-3x-small: 15px;
  --ignt-font-size-2x-small: 15px;
  --ignt-font-size-x-small: 16px;
  --ignt-font-size-small: 16px;
  --ignt-font-size-medium: 17px;
  --ignt-font-size-large: 17px;
  --ignt-font-size-x-large: 18px;
  --ignt-font-size-2x-large: 18px;
  --ignt-font-size-3x-large: 19px;
  --ignt-font-size-4x-large: 19px;

  --ignt-input-height-large: 2.5rem;

  /* Font Weights */
  --ignt-font-weight-light: 300;
  --ignt-font-weight-normal: 400;
  --ignt-font-weight-bold: 700;

  /* Default font stack. */
  --ignt-font-sans: 'Source Sans Pro', 'Open Sans', sans-serif;

  /* Fonts for headlines, blockquotes and alike. */
  --ignt-basic-font-head: 'Museo', 'Source Sans Pro', 'Open Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --ignt-basic-font-body: 'Source Sans Pro', 'Open Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --ignt-basic-font-mono: 'Source Code Pro', Courier, monospace;

  --sl-input-height-medium: 40px;
  --sl-input-height-large: 50px;

  --ignt-page-width-default: 1400px;
  --ignt-bind-padding: 20px;
  --ignt-bind-margin: .25em;

  --ignt-outer-space: 20px;
  --ignt-inner-space: 10px;
  --ignt-bottom-space: 20px;
  --ignt-grid-space: 20px;

  --ignt-border-radius: 0;
  --ignt-border-radius-default: var(--ignt-border-radius);

  --ignt-paragraph-width-default: 720px;

  /* Media queries / Supported screen sizes */
  --ignt-break-2x-small: 32em; /* old smart phones or gadgets like smart watches up to: 512px / 16px = 32em */
  --ignt-break-x-small: 54em; /* smart phones up to: 640px / 16px = 40em */
  --ignt-break-small: 80em; /* tablets up to: 1040px / 16px = 65em */
  --ignt-break-medium: 75em; /* screens: 1200px / 16px = 75em */
  --ignt-break-large: 94em; /* big screens up to: 1504px / 16px = 94em */
  --ignt-break-x-large: 105em; /* enough space left and right of the main binder: 1680px / 16px = 105em */
  --ignt-break-2x-large: 114em; /* huge screens: 1824px / 16px = 114em; */
  --ignt-break-3x-large: 225em; /* 4k screens: 3600px / 16px = 225em; */


  --ignt-default-shadow: 0 0 0 8px rgba(0, 0, 0, .25);
}
