@charset "UTF-8";

html{
  overflow-y: auto;

  @media (min-width: 112.5em) {
    font-size: 20px !important;
  }
}

body{
  background-color: #fff;
}

p{
  max-width: 100ch;
}

.main{
  min-height: calc(100vh - 100px);
}

.wrap{
  display: flex;
  flex-direction: column;
}

.bind{
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  padding: 20px;
  margin: 0 auto;

  &--with-header {
    margin-top: 100px; /* header height, because it's absolut */
  }

  &.bind--centered{
    align-items: center;
  }
}

.headline {
  font-weight: 900;
  font-family: var(--ignt-basic-font-head);
  color: var(--ignt-color-primary);
  line-height: 1.2;
  margin-bottom: 20px;
}

h1.headline{
  font-size: 3em;
}

h2.headline{
  font-size: 2.5em;
}

h3.headline{
  font-size: 2em;
}

.header{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: #fff;
  width: 100%;
  height: 100px;
  padding: 20px 30px;
  z-index: 1;
  background-image: url('/static/images/stage.jpg');
  background-size: cover;

  &.transparent{
    background: transparent;
  }
}

.logo{
  height: 100%;
  display: flex;
  padding-right: 40px;
  flex: 0 1 auto;
}

.logo-img{
  height: 100%;
}

.main-menu{
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-top: 15px;
}

.mM-item{
  color: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 20px;
  font-family: var(--ignt-basic-font-head);
  font-weight: 700;
  flex: 1 0 auto;

  &:hover, &:focus{
    color: #fff;
  }

  &.mM-item--invert{
    background-color: rgba(255, 255, 255, .85);
    border-radius: 5px;
    color: #100e0e;
    margin-left: 10px;
    transition: all ease .3s;

    @media (--ignt-mq-max-break-small){
      padding: 7px;

      img{
        margin-left: 0;
        margin-right: 0;
      }

      span{
        display: none;
      }
    }

    &:hover{
      background-color: rgba(255, 255, 255, 1);
    }
  }

  img{
    margin-left: -4px;
    margin-right: 8px;
  }

  @media (--ignt-mq-max-break-medium){
    font-size: .9em;
  }

  @media (--ignt-mq-max-break-small){
    padding: 7px 10px;
  }
}

.main-stage{
  width: 100%;
  background-image: url('/static/images/stage.jpg');
  background-size: cover;
  background-position: center bottom;
  position: relative;
  margin-bottom: 40px;
  padding: 120px 15px 40px 15px;
  height: 60vh;
  min-height: 650px;
  display: flex;
  font-size: 1.3em;

  .bind{
    justify-content: flex-end;
    flex-direction: column;
    z-index: 1;
  }

  .headline, .subline{
    color: #fff;
  }

  .headline{
    margin-bottom: 30px;
  }

  @media (--ignt-mq-min-break-medium){
    font-size: 1.4em;
    padding: 160px 15px 60px 15px;
  }

  @media (min-width: 500px) {
    .headline, .subline{
      max-width: 600px;
      width: 55vw;
      min-width: 400px;
    }
  }
}

.module-wrap{
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:hover{
    .module-grey-layer{
      background-color: rgba(0, 0, 0, 0);
    }
  }

  .link-cta{
    margin-top: auto;
  }
}

.mini-module-wrap{
  display: flex;
  flex-direction: row;
  align-items: center;
  background-size: cover;
  background-position: center;
  padding: 20px;
  font-size: .9em;

  .git-button-wrap{
    @media (--ignt-mq-max-break-medium){
      flex-direction: column;
      align-items: flex-end;
    }
  }
}

.mini-module-link{
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-right: auto;
}

.module-head{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 40px;
  width: 100%;
  height: auto;
  font-size: 1.25em;
  position: relative;
}

.module-grey-layer{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  transition: all ease .3s;
  background-color: rgba(0, 0, 0, .6);
  mix-blend-mode: luminosity;
}

.module-icon{
  width: 4.5em;
  height: 4.5em;
  margin-right: 20px;
  z-index: 1;
}

.module-headline{
  color: #fff;
  margin-bottom: 0;
  z-index: 1;
}

.git-button-wrap{
  display: flex;
  flex-direction: row;
  margin-top: 15px;

  span{
    margin-right: 10px;
  }
}

.module-txt{
  margin: 10px 0 20px 0;
  color: var(--ignt-color-text-default);
}

.link-cta{
  margin-top: 20px;
  padding: 5px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: underline;
  font-weight: bold;

  &.link-cta--grey{
    color: var(--ignt-color-text-default) !important;
    font-weight: normal;
  }
}

.footer{
  background-color: #030511;
  width: 100%;
  margin-top: 40px;

  @media (--ignt-mq-min-break-medium){
    margin-right: 40px;
    padding: 20px 10px;
  }
}

.footer-row{
  display: flex;
  flex-direction: row;

  @media (--ignt-mq-max-break-medium){
    flex-direction: column-reverse;
  }
}

.footer-link-wrap{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: auto;

  @media (--ignt-mq-max-break-medium){
    margin: 0 auto 20px auto;
  }
}

.footer-link{
  color: #fff;
  font-family: var(--ignt-basic-font-head);
  padding: 0 0 0 20px;

  &:hover{
    color: #fff;
  }

  @media (--ignt-mq-max-break-medium){
    padding: 0 0 0 10px;
  }
}

.footer-cite{
  color: #fff;
  font-family: var(--ignt-basic-font-head);
  text-align: center;

  @media (--ignt-mq-min-break-medium){
    margin-right: 20px;
    text-align: left;
  }
}

.copyright-link{
  font-size: .9em;
  margin-top: 20px;
  color: #fff;
  text-align: center;
  width: 100%;
}

sl-button{
  &::part(base){
    transition: all ease .3s;
  }
}

a:has(sl-button) + a:has(sl-button){
    display: flex;
    margin-top: var(--sl-spacing-small);
}
